import type { Rule } from '@/plugins/casl/ability';
import { DirectusUsers } from '@thunder/core/src/services/baas/directus/schema';

export class User extends DirectusUsers {
	abilityRules?: Rule[];

	constructor(data: unknown) {
		super();

		Object.assign(this, data);
	}

	toJSON() {
		return {
			...this,
		};
	}
}
