import { User } from '@/thunder/modules/user/user.model';
import {
	readUser,
	readUsers,
	updateUser,
	type DirectusUser,
	type Query,
} from '@directus/sdk';
import type { Schema } from '@thunder/core/src/services/baas/directus/schema';

interface State {
	_list: User[];
	_current?: User;
}

export const useUserStore = () => {
	const { $baas } = useNuxtApp();

	return defineStore('user', {
		state: (): State => ({
			_list: [],
			_current: undefined,
		}),
		getters: {
			current: state => state._current,
			list: state => state._list,

			/**
			 *
			 * This getter will attempt to fill relationships from existing records in relationship stores.
			 * Take note that this will reactivate when the relationship stores are updated.
			 * See {@link useUserStore#fillRelations}
			 */
			get: state => {
				return (id: string): User | null => {
					// if (invoice) {
					// 	return getRelations(invoice);
					// }

					return state._list?.find(item => item.id === id) ?? null;
				};
			},
		},
		actions: {
			async fetchMany(query?: Query<Schema, DirectusUser<Schema>>) {
				const data = await $baas.request(readUsers(query));

				for (const item of data) {
					this.listUpsert(item.id, item);
				}

				return this._list;
			},

			async fetch(id: string, query?: Query<Schema, DirectusUser<Schema>>) {
				const data = await $baas.request(readUser(id, query));

				this.listUpsert(data.id, data);
			},

			async update(id: string, data: Partial<User>) {
				const updated = await $baas.request(updateUser(id, data as any));

				this.listUpsert(updated.id, updated);
			},

			listUpsert(id: string, data: object) {
				const index = this._list.findIndex(_item => _item.id === id);
				if (index >= 0) {
					this._list[index] = new User(data);
				} else {
					this._list.push(new User(data));
				}
			},

			setCurrent(data: Partial<User>) {
				this._current = new User(data);
				this.listUpsert(this._current.id, data);
			},
		},
	});
};
