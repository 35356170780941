<script setup lang="ts">
import type { Site } from '@/thunder/modules/site/site.model';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { VList } from 'vuetify/lib/components/index.mjs';

interface Props {
	sites: Site[];
}
interface Emits {
	(e: 'siteChanged', val: number): void;
	(e: 'search', value: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const refSearchList = ref<VList>();
const searchQueryLocal = ref('');

const getFocusOnSearchList = (e: KeyboardEvent) => {
	if (e.key === 'ArrowDown') {
		e.preventDefault();
		refSearchList.value?.focus('next');
	} else if (e.key === 'ArrowUp') {
		e.preventDefault();
		refSearchList.value?.focus('prev');
	}
};
</script>

<template>
	<VDialog
		max-width="600"
		:height="$vuetify.display.smAndUp ? '531' : '100%'"
		:fullscreen="$vuetify.display.width < 600"
	>
		<template #default="{ isActive }">
			<VCard height="100%" width="100%" class="position-relative">
				<VCardText class="px-4" style="padding-block: 1rem 1.2rem">
					<!-- 👉 Search Input -->
					<VTextField
						ref="refSearchInput"
						v-model="searchQueryLocal"
						autofocus
						density="compact"
						variant="plain"
						class="app-bar-search-input"
						placeholder="Search for the site you want to change to"
						@keyup.esc="isActive.value = false"
						@keydown="getFocusOnSearchList"
						@update:model-value="$emit('search', searchQueryLocal)"
					>
						<!-- 👉 Prepend Inner -->
						<template #prepend-inner>
							<div class="d-flex align-center text-high-emphasis me-1">
								<VIcon size="24" icon="tabler-search" />
							</div>
						</template>

						<!-- 👉 Append Inner Dialog close btn  -->
						<template #append-inner>
							<div class="d-flex align-start">
								<div
									class="text-base text-disabled cursor-pointer me-3"
									@click="isActive.value = false"
								>
									[esc]
								</div>

								<VIcon
									icon="tabler-x"
									size="24"
									@click="isActive.value = false"
								/>
							</div>
						</template>
					</VTextField>
				</VCardText>

				<!-- 👉 Divider -->
				<VDivider />

				<!-- 👉 Perfect Scrollbar -->
				<PerfectScrollbar
					:options="{ wheelPropagation: false, suppressScrollX: true }"
					class="h-100"
				>
					<VList ref="refSearchList" lines="two" density="compact" class="py-0">
						<template v-for="(site, index) of props.sites" :key="index">
							<VListItem :value="site.id" @click="emit('siteChanged', site.id)">
								<template #prepend>
									<VIcon icon="tabler-car"></VIcon>
								</template>
								<VListItemTitle>
									{{ site.name }}
								</VListItemTitle>
								<VListItemSubtitle class="mt-1">
									<span class="text-xs text-disabled">{{ site.location }}</span>
								</VListItemSubtitle>
							</VListItem>
							<VDivider v-if="index !== sites.length - 1" />
						</template>
					</VList>
				</PerfectScrollbar>
			</VCard>
		</template>
	</VDialog>
</template>
